import './src/css/tailwind.css';
import "@fontsource/bebas-neue";
import "@fontsource/lato";

export const onRouteUpdate = () => {
  // Get the button
  let mybutton = document.getElementsByClassName("btn-back-to-top")[0];
        
  if (typeof window !== 'undefined' && typeof mybutton !== 'undefined') {
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };

    // When the user clicks on the button, scroll to the top of the document
    mybutton.addEventListener("click", backToTop);

    // Initial load display none
    mybutton.style.display = "none";
  }
  
  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}

export const onInitialClientRender = () => {
  async function asyncCall_removeWhatsAppButtonDescription() {
    const result = await action_removeWhatsAppButtonDescription();
    console.log(result);
  }
  function action_removeWhatsAppButtonDescription() {
      return new Promise(resolve => {
        setTimeout(() => {
          if (document.getElementsByClassName('sbsi7l-0').length > 0)
              document.getElementsByClassName('sbsi7l-0')[0].remove();
          resolve('Whatsapp button - remove bottom description.');
        }, 2000);
      });
  }

  async function asyncCall_addGoogleAnalyticClickEventToWhatsAppButton() {
    const result = await action_addGoogleAnalyticClickEventToWhatsAppButton();
    console.log(result);
  }
  function action_addGoogleAnalyticClickEventToWhatsAppButton() {
      return new Promise(resolve => {
        setTimeout(() => {
          
          const links = document.querySelectorAll('[href="https://wa.me/5491169041851?text=Hola%2C%20quiero%20recibir%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20planes%20de%20correr."]');
          [].forEach.call(links, function(link) {
            // do whatever
            link.addEventListener('click',function() {
              if (typeof window !== 'undefined') {
                window.gtag('event', 'whatsapp', {
                  'event_category': 'boton'
                });
              }
            }, false);
            console.log(link);
          });
          resolve('Whatsapp links - add google analytics click event.');
        }, 3000);
      });
  }
  
  //asyncCall_removeWhatsAppButtonDescription();
  //asyncCall_addGoogleAnalyticClickEventToWhatsAppButton();
}