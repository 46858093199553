exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-checkout-paypal-js": () => import("./../../../src/pages/checkout-paypal.js" /* webpackChunkName: "component---src-pages-checkout-paypal-js" */),
  "component---src-pages-como-correr-mas-rapido-js": () => import("./../../../src/pages/como-correr-mas-rapido.js" /* webpackChunkName: "component---src-pages-como-correr-mas-rapido-js" */),
  "component---src-pages-como-empezar-a-correr-js": () => import("./../../../src/pages/como-empezar-a-correr.js" /* webpackChunkName: "component---src-pages-como-empezar-a-correr-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loc-elite-js": () => import("./../../../src/pages/loc-elite.js" /* webpackChunkName: "component---src-pages-loc-elite-js" */),
  "component---src-pages-plan-para-correr-whatsapp-display-js": () => import("./../../../src/pages/plan-para-correr-whatsapp-display.js" /* webpackChunkName: "component---src-pages-plan-para-correr-whatsapp-display-js" */),
  "component---src-pages-plan-para-correr-whatsapp-js": () => import("./../../../src/pages/plan-para-correr-whatsapp.js" /* webpackChunkName: "component---src-pages-plan-para-correr-whatsapp-js" */)
}

